.Table {
  thead tr th,
  tbody tr td {
    border-bottom: 1px solid #D2D9DF;
    padding: 16px 24px;
  }

  thead {
    tr {
      th {
        background-color: #F9FAFE;
        border-top: 1px solid #D2D9DF;
        color: #76849F;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }

  tbody {
    tr {
      td {
        color: #1A3251;

        svg {
          fill: #D2D9DF;
          margin-right: 8px;
          vertical-align: -0.125em;
        }

        > a {
          color: #1A3251;
          text-decoration: none;
        }
      }
    }
  }
}
