.Row {
  min-height: 100vh;
}

.Sidebar {
  background-color: white;
}

.Logo {
  padding-right: 20px;
  padding-left: 20px;
}

.LogoImage {
  flex: 1 1 0;
  max-width: 160px;
  min-width: 0;
}

.LogoIcon {
  vertical-align: -0.125em;
}
