.Backdrop {
  background-color: #BAC5CF;
}

.Content {
  border: 0;
  border-radius: 0;
}

.Header {
  color: #193251;
  border-bottom: 0;
}

.Body {
  color: #193251;
}

.Footer {
  border-top: 0;
}
