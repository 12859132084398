.Link {
  color: #76849F;
  border-left: 4px solid transparent;

  &:hover {
    background-color: #EFF6FC;
    border-color: #0067BE;
    color: #0067BE;
  }
}

.Active {
  background-color: #EFF6FC;
  border-color: #0067BE;
  color: #0067BE;
}

.Icon {
  vertical-align: -0.125em;
}
