.Input {
  border-right: 0;
}

.Label {
  background-color: white;
  border-color: #D2D9DF;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-left: 0;
  color: #76849F;

  &:active,
  &:focus,
  &:hover {
    background-color: white;
    border-color: #D2D9DF;
    color: #1A3251;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(26 50 81 / 25%);
  }

  svg {
    vertical-align: -0.125em;
  }
}
