.Item {
  &:global(.active .page-link) {
    background-color: #0067BE;
    border-color: #0067BE;
  }

  :global(.page-link) {
    color: #0067BE;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(0 103 190 / 25%);
    }

    &:first-child {
      border-radius: 0;
    }
  }
}
