.Draggable {
  border: 1px dashed #D2D9DF;
  color: #76849F;
  text-align: center;
}

.DragOver {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgb(0 103 190 / 25%);
}
