.Primary {
  background-color: #0067BE;
  border-radius: 0;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 103 190 / 25%);
  }
}

.Link {
  border-radius: 0;
  color: #0067BE;
  text-decoration: none;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 103 190 / 25%);
  }
}
