.Menu {
  ol {
    margin-bottom: 4px;
  }
}

.Item {
  font-weight: 700;

  &:not(:first-child)::before {
    color: #676767;
    content: ">";
  }

  :global(a) {
    color: #0066BE;
    text-decoration: none;
  }
}
